import { E_LoginMethod } from 'library-components/apis/login/login.type';
/**
 * 可通过无界微前端配置的登录页元素
 */
export var WUJIE_PROPS_USER_ENTRY_METHOD;
(function (WUJIE_PROPS_USER_ENTRY_METHOD) {
    /**
     * 微信登录注册
     */
    WUJIE_PROPS_USER_ENTRY_METHOD["Wechat"] = "wechat";
    /**
     * 手机号登录注册
     */
    WUJIE_PROPS_USER_ENTRY_METHOD["SMS"] = "sms";
    /**
     * 账号密码登录
     */
    WUJIE_PROPS_USER_ENTRY_METHOD["Account"] = "account";
    /**
     * sso carsi/saml登录注册
     */
    WUJIE_PROPS_USER_ENTRY_METHOD["SSO"] = "sso";
    /**
     * google登录
     */
    WUJIE_PROPS_USER_ENTRY_METHOD["Google"] = "google";
    /**
     * 免费注册
     */
    WUJIE_PROPS_USER_ENTRY_METHOD["FreeRegister"] = "freeRegister";
})(WUJIE_PROPS_USER_ENTRY_METHOD || (WUJIE_PROPS_USER_ENTRY_METHOD = {}));
export class WujieService {
    get $wujie() {
        // @ts-ignore
        return window?.$wujie;
    }
    get props() {
        return this.$wujie?.props;
    }
    /**
     * 获取定制化文案
     */
    get text() {
        return this.props?.text;
    }
    get userEntryMethods() {
        return this.props?.userEntryMethods || [];
    }
    isLoginMethodEnabled(method) {
        return !this.userEntryMethods.length || this.userEntryMethods.includes(method);
    }
    get WechatLoginEnabled() {
        return this.isLoginMethodEnabled(WUJIE_PROPS_USER_ENTRY_METHOD.Wechat);
    }
    get SMSLoginEnabled() {
        return this.isLoginMethodEnabled(WUJIE_PROPS_USER_ENTRY_METHOD.SMS);
    }
    get AccountLoginEnabled() {
        return this.isLoginMethodEnabled(WUJIE_PROPS_USER_ENTRY_METHOD.Account);
    }
    get SSOLoginEnabled() {
        return this.isLoginMethodEnabled(WUJIE_PROPS_USER_ENTRY_METHOD.SSO);
    }
    get GoogleLoginEnabled() {
        return this.isLoginMethodEnabled(WUJIE_PROPS_USER_ENTRY_METHOD.Google);
    }
    get FreeRegisterEnabled() {
        return this.isLoginMethodEnabled(WUJIE_PROPS_USER_ENTRY_METHOD.FreeRegister);
    }
    get ActiveLoginCard() {
        const activeLoginCard = this.props?.activeLoginCard;
        switch (activeLoginCard) {
            case WUJIE_PROPS_USER_ENTRY_METHOD.SMS:
                return E_LoginMethod.sms;
            case WUJIE_PROPS_USER_ENTRY_METHOD.Account:
                return E_LoginMethod.password;
            case WUJIE_PROPS_USER_ENTRY_METHOD.Wechat:
                return E_LoginMethod.wechat;
            // 添加其他可能的情况
            default:
                return '';
        }
    }
    get loginFormParams() {
        return {
            mobile: this.props?.loginFormParams?.mobile || '',
            account: this.props?.loginFormParams?.account || '',
        };
    }
}
