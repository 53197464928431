import { bindWechatOpenIdApi } from 'library-components/apis/wechat/wechat';
import { AbstractLoginSuccess } from './login-success-service';
import { Toast } from 'patsnap-biz';
import { goToModuleForRegister } from 'library-components/utils/goToModule';
export const WechatBindLoginSuccessService = Symbol('WechatBindLoginSuccessService');
export class WechatBindLoginSuccessServiceImpl extends AbstractLoginSuccess {
    bindWechatError = false;
    goToModule(opts) {
        return goToModuleForRegister(opts);
    }
    async bindWechatOpenId(callbackValue) {
        this.bindWechatError = false;
        await bindWechatOpenIdApi({ callback_value: callbackValue })
            .catch(err => {
            console.log('wechat bind err:', err);
            this.bindWechatError = true;
            Toast({
                type: 'warning',
                message: this.t('passport.wechat.bindError'),
                showClose: true,
                dangerouslyUseHTMLString: true,
                duration: 3000,
            });
        });
    }
    async handleLoginSuccess(opts) {
        const { callbackValue, params, token } = opts;
        this.applyAuthInterceptor(token);
        await this.bindWechatOpenId(callbackValue);
        if (this.bindWechatError) {
            await new Promise(resolve => setTimeout(resolve, 3000));
        }
        await Promise.all([
            this.handleLocaleChange(),
            this.goToModule({ params })
        ]);
    }
}
