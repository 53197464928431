import { getVar } from 'library-components/utils/environment';
import { getUseLoginSuccessPageApi } from 'library-components/apis/login/login';
import { SLD, transformUrlProxy } from '@patsnap-utils/biz';
import { get } from 'lodash';
import { ANALYTICS_URI } from 'library-components/config/envConfig.js';
import { jumpTo } from './jump';
let redirect_uri = getVar('redirect_uri');
const noRedirect = getVar('no_redirect', 'boolean');
export async function goToModuleURI(opts, redirectUrl) {
    let redirectUri = '';
    const isDirectLogin = get(opts, 'isDirectLogin', false);
    if (isDirectLogin) {
        redirect_uri = '';
        redirectUri = transformUrlProxy(SLD.ANALYTICS, ANALYTICS_URI); // 特殊IP直接登录进入搜索
    }
    else {
        try {
            let getPageBody = {
                last_page: '',
            };
            if (redirect_uri || redirectUrl) {
                getPageBody = {
                    last_page: redirectUrl || redirect_uri,
                };
            }
            const loginSuccessData = await getUseLoginSuccessPageApi(getPageBody);
            const { page, product } = loginSuccessData;
            /**
             *  直接登录的不返回product，其它情况都会返回product
             */
            redirectUri = product ? transformUrlProxy(product, page) : page;
        }
        catch (error) {
            console.log(error);
        }
    }
    if (redirectUri) {
        const protocol = window.location.protocol;
        redirectUri = redirectUri.replace(/^https?:/, protocol);
        if (noRedirect) {
            console.warn('redirect_url:', redirectUri);
            return;
        }
        return redirectUri;
    }
}
export async function goToModule(opts, redirectUrl) {
    const redirectUri = await goToModuleURI(opts, redirectUrl);
    if (redirectUri) {
        jumpTo(redirectUri);
    }
}
export async function goToModuleForRegister(opts, redirectUrl) {
    const redirectUri = await goToModuleURI(opts, redirectUrl);
    if (!redirectUri)
        return;
    const params = opts?.params;
    // 如果redirectUri为产品选择页，则需要带上参数
    if (redirectUri.includes('portal/#/product-select') && params) {
        const url = new URL(redirectUri);
        Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));
        jumpTo(url.toString());
    }
    else {
        jumpTo(redirectUri);
    }
}
