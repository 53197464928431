import { getDomainByCompanyIdApi, getInvitationInvitor, getInvitationShortCode, postJoinTenantApi, postPreLoginCheckApi, postPreLoginMobileOrEmailVerifyCode, preMobileLoginApi } from 'library-components/apis/login/login';
import { E_LoginMethod } from 'library-components/apis/login/login.type';
import { registerUrlParamKeys } from 'library-components/apis/register/register.type';
import { postPreLoginIdentifyToken } from 'library-components/apis/wechat/wechat';
import { getAllVarsForLogin, postVar } from 'library-components/utils/environment';
import { ACCOUNT_URI } from 'library-components/config/envConfig.js';
import { getAccountPassWordKeepLoginStatus, getAccountSMSKeepLoginStatus, getLoginLocaleStatus, removeLoginLocaleStatus } from 'library-components/services/localStorage';
import { goToMoudle } from '@/infrastructure/utils/goToMoudle';
import { setLangValue } from 'library-components/utils/langUtils';
import { E_Switch } from 'library-components/apis/passport/passport.type';
import { CommonService } from 'library-components/services/commonService';
import { getProfileApi } from 'library-components/apis/core_basic_api/user-settings';
import _ from 'lodash';
export class LoginService extends CommonService {
    constructor({ i18n }) {
        super();
        this.i18n = i18n;
        this.init();
    }
    i18n;
    status = null;
    init() {
    }
    get loginStatus() {
        return this.status;
    }
    set loginStatus(status) {
        this.status = status;
    }
    async getPreLoginData(params, loginType) {
        let multiData;
        if (loginType === E_LoginMethod.password) {
            multiData = await postPreLoginCheckApi(params);
        }
        else if (loginType === E_LoginMethod.mobilePassword) {
            multiData = await preMobileLoginApi(params);
        }
        else if (loginType === E_LoginMethod.sms || loginType === E_LoginMethod.emailVerifyCode) {
            multiData = await postPreLoginMobileOrEmailVerifyCode(params);
        }
        else if (loginType === E_LoginMethod.wechat) {
            multiData = await postPreLoginIdentifyToken(params);
        }
        else {
            console.error('Invalidate E_LoginMethod:', loginType);
        }
        return multiData;
    }
    async loginSuccess(opts) {
        const localeChanged = getLoginLocaleStatus();
        if (localeChanged) {
            try {
                await setLangValue(this.i18n.locale, true).then(() => {
                    removeLoginLocaleStatus();
                });
            }
            catch (error) {
                console.log(error);
            }
        }
        await goToMoudle(opts);
    }
    getResetPasswordUrl(params, loginType) {
        const accountHost = ACCOUNT_URI;
        const resetPasswordUrlQueries = {
            remember_me: E_Switch.Off,
            token: params.token,
            strongPwd: params.strong_password_need,
            tenant_id: params.tenant_id,
        };
        let keepLogin = 'false';
        if (loginType === E_LoginMethod.password) {
            keepLogin = getAccountPassWordKeepLoginStatus();
            resetPasswordUrlQueries.email = params.username;
        }
        if (loginType === E_LoginMethod.mobilePassword) {
            keepLogin = getAccountSMSKeepLoginStatus();
            resetPasswordUrlQueries.mobile_number = params.mobile_number;
        }
        resetPasswordUrlQueries.remember_me = keepLogin === 'true' ? E_Switch.On : E_Switch.Off;
        const paramsUrl = this.getParamsUrl(params.username);
        return `${accountHost}/public/?${paramsUrl}#/resetPassword?${postVar(resetPasswordUrlQueries)}`;
    }
    getParamsUrl(email) {
        const params = getAllVarsForLogin(['from', 'redirect_uri', 'client_id', ...registerUrlParamKeys]);
        return postVar({ ...params, page_email: email });
    }
    _invitationData = {};
    get invitationData() {
        return this._invitationData;
    }
    async initInvitationData() {
        if (!_.isEmpty(this._invitationData))
            return this._invitationData;
        if (this.IsPersonalInvitation) {
            const data = await getInvitationInvitor(this.InviterId);
            if (data.status) {
                this._invitationData.invitationUser = data.data;
            }
        }
        if (this.InvitationSortCode) {
            const data = await getInvitationShortCode(this.InvitationSortCode);
            this._invitationData.invitationCompany = data?.company_name;
            this._invitationData.companyId = data?.company_id;
        }
        if (this.InvitationCompanyId) {
            const data = await getDomainByCompanyIdApi(this.InvitationCompanyId);
            this._invitationData.invitationCompany = data?.company_name;
            this._invitationData.companyId = data?.company_id;
        }
        return this._invitationData;
    }
    async getProfile() {
        const data = await getProfileApi();
        return data;
    }
    async postJoinTenantApi(companyId, invitationSortCode) {
        const data = await postJoinTenantApi(companyId, invitationSortCode);
        return data;
    }
}
