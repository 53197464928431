import { LOGIN_ROUTE_NAME } from './routes.type';
const routes = [
    {
        path: '/',
        name: LOGIN_ROUTE_NAME.MAIN_LOGIN,
        component: () => import(/* webpackChunkName: "mainLogin" */ '@/presentation/pages/MainLogin/MainFrame.vue'),
    },
    {
        path: '/eureka-login',
        name: LOGIN_ROUTE_NAME.EUREKA_LOGIN,
        component: () => import(/* webpackChunkName: "eurekaLogin" */ '@/presentation/pages/EurekaLogin/MainFrame.vue'),
    },
    {
        path: '/sub-login',
        name: LOGIN_ROUTE_NAME.SUB_LOGIN,
        component: () => import(/* webpackChunkName: "subLogin" */ '@/presentation/pages/SubLogin/SubLogin.vue'),
    },
    {
        path: '/*',
        redirect: '/',
    }
];
export default routes;
