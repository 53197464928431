// @ts-nocheck
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { E_Language } from 'library-components/config/types';
import _ from 'lodash';
Vue.use(VueI18n);
Vue.mixin({
    computed: {
        $lang() {
            return this.$i18n.locale;
        },
    },
});
let i18n;
export function makeLocale(lang, fallbackLocale = 'en') {
    i18n = new VueI18n({
        locale: lang,
        fallbackLocale,
    });
    return i18n;
}
export async function fetchMessages(i18n, lang) {
    let results = [];
    if (lang === E_Language.cn) {
        const { message } = await import('./importCn');
        results = await Promise.all(message);
    }
    else if (lang === E_Language.en) {
        const { message } = await import('./importEn');
        results = await Promise.all(message);
    }
    else if (lang === E_Language.jp) {
        const { message } = await import('./importJp');
        results = await Promise.all(message);
    }
    else if (lang === E_Language.tw) {
        const { message } = await import('./importTw');
        results = await Promise.all(message);
    }
    const message = results.reduce((obj, data) => {
        return _.merge(obj, data);
    }, {});
    i18n.locale = lang;
    i18n.mergeLocaleMessage(lang, message);
    return i18n;
}
