import { baseAuthServer, userBasicApiUrlPrefix, baseUserServer, } from '../../config/apiConfig';
import _ from 'lodash';
import psHttp from '@patsnap/common-ps-http';
import qs from 'qs';
import { E_LanguageLong } from 'library-components/apis/passport/types';
export function loginInitApi(data = {}) {
    return psHttp({
        url: `${baseAuthServer}/public/login/init`,
        method: 'GET',
        params: data,
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function checkSessionApi(data) {
    return psHttp({
        url: `${baseAuthServer}/checksession`,
        method: 'GET',
        withCredentials: true,
        params: data,
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function postPreLoginCheckApi(data) {
    return psHttp({
        url: `${baseAuthServer}/public/pre_login`,
        method: 'POST',
        data: data,
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function getPublicKeyApi(data = {}) {
    return psHttp({
        url: `${baseAuthServer}/public/request_public_key`,
        method: 'GET',
        params: data,
    }).then(res => {
        return _.get(res, 'data');
    });
}
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export function loginSubmitApi(data, resGeetestValidate) {
    return psHttp({
        url: `${baseAuthServer}/loginsubmit`,
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Version': '1.0',
        },
        params: { ...data, ...resGeetestValidate },
    }).then(async (res) => {
        const data = res.data;
        // PBI-6957
        if (data && data.token) {
            if (data.replay_url) {
                if (window.location.search.includes('noraven')) {
                    throw new Error('debug stop');
                }
                window.location.replace(data.replay_url);
                await sleep(6 * 1000);
            }
            else {
                return data;
            }
        }
        return data;
    });
}
/**
 * 手机号密码登录
 */
export function mobileLoginApi(data, resGeetestValidate) {
    return psHttp({
        url: `${baseAuthServer}/login/mobile-number`,
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Version': '1.0',
        },
        params: { ...data, ...resGeetestValidate },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 手机号注册登录接口
 */
export function mobileLoginRegisterApi({ verify_code, from, remember_me, }, data) {
    return psHttp({
        url: `${baseAuthServer}/login/with-register?from=${from}&verify_code=${verify_code}&remember_me=${remember_me}`,
        method: 'POST',
        withCredentials: true,
        data,
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 手机号密码登录check
 */
export function preMobileLoginApi(data) {
    return psHttp({
        url: `${baseAuthServer}/public/pre_login/mobile-number/password`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Version': '1.0',
        },
        params: data,
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function preLogoutApi() {
    return psHttp({
        url: `${baseAuthServer}/logout`,
        method: 'GET',
        withCredentials: true,
    });
}
export function getQueryIdpsApi(name) {
    return psHttp({
        url: `${baseAuthServer}/public/supported/idps/query`,
        method: 'GET',
        params: {
            name,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function getUseLoginSuccessPageApi(lastPage) {
    return psHttp({
        url: `${baseUserServer}/user-service/account-login/success-page`,
        method: 'POST',
        data: lastPage,
    }).then(res => {
        return _.get(res, 'data.data');
    });
}
/**
 * 发送验证码
 */
export function postMobileVerifyCodeApi({ purpose = '', country_code = '', mobile_number, lang }, resGeetestValidate) {
    return psHttp({
        url: `${baseAuthServer}/public/mobile-number/verify-code`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
            purpose,
            country_code,
            mobile_number,
            language: E_LanguageLong[lang || 'en'],
            ...resGeetestValidate,
        }),
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 手机号验证码登录
 */
export function postLoginVerifyCode(data) {
    return psHttp({
        url: `${baseAuthServer}/login/mobile-number/verify-code`,
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify(data),
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 邮箱、手机号验证码登录pre_login
 */
export function postPreLoginMobileOrEmailVerifyCode(params) {
    return psHttp({
        url: `${baseAuthServer}/public/pre_login/mobile/email/verify-code`,
        method: 'POST',
        data: params,
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function postLoginEmailVerifyCode(params) {
    return psHttp({
        url: `${baseAuthServer}/login/email/verify-code`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
        data: qs.stringify(params),
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 手机号找回密码
 */
export function postForgetMobileNumberToken({ country_code, mobile_number, verify_code }) {
    return psHttp({
        url: `${baseAuthServer}/public/forget/mobile-number/token`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({
            country_code,
            mobile_number,
            verify_code,
        }),
    }).then(res => {
        return _.get(res, 'data');
    });
}
export function getCountryCode() {
    return psHttp({
        url: 'http://ip-api.com/json?fields=countryCode',
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 激活
 */
export function postIdentifyTokenEmailBinding({ identify_token, client_id }) {
    return psHttp({
        url: `${baseAuthServer}/login/identify-token-email-binding`,
        method: 'POST',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: qs.stringify({ identify_token, client_id }),
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 开启domain限制让用户补录邮箱
 */
export function postLoginAppendEmail(params) {
    return psHttp({
        url: `${baseAuthServer}/login/append/email`,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'post',
        params,
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 获取用户信息
 */
export function getInvitationInvitor(invitorId) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/invitation/invitor`,
        method: 'GET',
        params: {
            invitorId,
        },
    }).then(res => {
        return _.get(res, 'data');
    });
}
/**
 * 获取admin邀请信息
 */
export function getInvitationShortCode(shortCode) {
    return psHttp({
        url: `${userBasicApiUrlPrefix}/invitation/short-code`,
        method: 'GET',
        params: {
            shortCode,
        },
    }).then(res => {
        return _.get(res, 'data.data');
    });
}
export function postLoginSocialIndividualApi(source, data) {
    return psHttp({
        url: `${baseAuthServer}/login/social-individual/${source}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Version': '1.0',
        },
        withCredentials: true,
        params: {
            ...data,
        },
    }).then((res) => {
        return _.get(res, 'data');
    });
}
export function postLoginSocialIndividualPasswordApi(source, data) {
    return psHttp({
        url: `${baseAuthServer}/login/social-individual/${source}/password`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-API-Version': '1.0',
        },
        withCredentials: true,
        params: {
            ...data,
            from: 'passport',
        },
    }).then((res) => {
        return _.get(res, 'data');
    });
}
// post /public/tenant-select-infos
export function postTenantSelectInfosApi(params) {
    return psHttp({
        url: `${baseAuthServer}/public/tenant-select-infos`,
        method: 'POST',
        withCredentials: true,
        data: params,
    }).then((res) => {
        return _.get(res, 'data');
    });
}
export function getDomainByCompanyIdApi(companyId) {
    return psHttp({
        url: `${baseUserServer}/user-service/public/company/domain-register`,
        method: 'GET',
        params: {
            company_id: companyId,
        },
    }).then((res) => {
        return _.get(res, 'data');
    });
}
export function postJoinTenantApi(tenantId, invitationSortCode = '') {
    return psHttp({
        url: `${baseUserServer}/user-service/tenant-user/join`,
        method: 'POST',
        params: {
            tenant_id: tenantId,
            invitation_code: invitationSortCode,
        },
    }).then((res) => {
        return _.get(res, 'data');
    });
}
