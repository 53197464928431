import { E_PRODUCTS_NAME } from '../type';
export var E_PRODUCT_IDS;
(function (E_PRODUCT_IDS) {
    /**
     * 数据库
     */
    E_PRODUCT_IDS["Analytics"] = "pro";
    E_PRODUCT_IDS["Discovery"] = "a2f4c064c228430a84f6087b5f870487";
    E_PRODUCT_IDS["Bio"] = "563398857b284d14a48575e06990b9e4";
    E_PRODUCT_IDS["Chemical"] = "chemical";
    E_PRODUCT_IDS["Synapse"] = "e5ce470917314759bb2a3184b6e62ca8";
    E_PRODUCT_IDS["Synapse_CN"] = "895f1015a21741bb97019a928c2c578b";
    E_PRODUCT_IDS["LS360"] = "ls360";
    E_PRODUCT_IDS["Insights"] = "report";
    /**
     * IpManagement
     */
    E_PRODUCT_IDS["Innosnap"] = "innosnap_product";
    E_PRODUCT_IDS["Course2b"] = "623ddd4fb7f549a7bcdf49b828a9f2ac";
    E_PRODUCT_IDS["TFFI"] = "974ff55f8941429fa7d7f81bd1a42023";
    E_PRODUCT_IDS["Academy"] = "fa02dbb62b9248adb83135957c22ae44";
    E_PRODUCT_IDS["Eureka"] = "bae91377ed7b45209cd48e076392cbbb";
})(E_PRODUCT_IDS || (E_PRODUCT_IDS = {}));
export const productNameKey = {
    /**
     * $t("passport.products.analytics")
     */
    [E_PRODUCT_IDS.Analytics]: 'passport.products.analytics',
    /**
     * $t("passport.products.discovery")
     */
    [E_PRODUCT_IDS.Discovery]: 'passport.products.discovery',
    /**
     * $t("passport.products.lifescience")
     */
    [E_PRODUCT_IDS.Bio]: 'passport.products.lifescience',
    /**
     * $t("passport.products.chemical")
     */
    [E_PRODUCT_IDS.Chemical]: 'passport.products.chemical',
    /**
     * $t("passport.products.synapse")
     */
    [E_PRODUCT_IDS.Synapse]: 'passport.products.synapse',
    /**
     * $t("passport.products.ls360")
     */
    [E_PRODUCT_IDS.LS360]: 'passport.products.ls360',
    /**
     * $t("passport.products.insights")
     */
    [E_PRODUCT_IDS.Insights]: 'passport.products.insights',
    /**
     * $t("passport.products.inno")
     */
    [E_PRODUCT_IDS.Innosnap]: 'passport.products.inno',
    /**
     * $t("passport.products.academy")
     */
    [E_PRODUCT_IDS.Academy]: 'passport.products.academy',
    /**
     * $t('passport.products.eureka')
     */
    [E_PRODUCT_IDS.Eureka]: 'passport.products.eureka',
    /**
     * $t('passport.products.synapse')
     */
    [E_PRODUCT_IDS.Synapse_CN]: 'passport.products.synapse',
    /**
     * $t('passport.products.tffi')
     */
    [E_PRODUCT_IDS.TFFI]: 'passport.products.tffi',
};
/**
 * 产品的名映射试用的产品id
 */
export const productNameMappingTrialProductId = {
    [E_PRODUCTS_NAME.analytics]: E_PRODUCT_IDS.Analytics,
    [E_PRODUCTS_NAME.insights]: E_PRODUCT_IDS.Insights,
    [E_PRODUCTS_NAME.bio]: E_PRODUCT_IDS.Bio,
    [E_PRODUCTS_NAME.chemical]: E_PRODUCT_IDS.Chemical,
    [E_PRODUCTS_NAME.discovery]: E_PRODUCT_IDS.Discovery, // 仅外国
    [E_PRODUCTS_NAME.eureka]: E_PRODUCT_IDS.Eureka,
    [E_PRODUCTS_NAME.inno]: E_PRODUCT_IDS.Innosnap, // 仅中国
    [E_PRODUCTS_NAME.synapse]: E_PRODUCT_IDS.Synapse, // 仅中国
    [E_PRODUCTS_NAME.synapse_cn]: E_PRODUCT_IDS.Synapse_CN, // 仅中国
    [E_PRODUCTS_NAME.tffi]: E_PRODUCT_IDS.TFFI, // 仅中国
};
export var E_OPEN_REGISTER_SUCCESS_STATUS;
(function (E_OPEN_REGISTER_SUCCESS_STATUS) {
    E_OPEN_REGISTER_SUCCESS_STATUS["SUCCESS"] = "SUCCESS";
    E_OPEN_REGISTER_SUCCESS_STATUS["NO_SEAT"] = "NO_SEAT";
    E_OPEN_REGISTER_SUCCESS_STATUS["NO_SCOPE"] = "NO_SCOPE";
})(E_OPEN_REGISTER_SUCCESS_STATUS || (E_OPEN_REGISTER_SUCCESS_STATUS = {}));
/**
 * 用户创建来源
 */
export var E_ACCOUNT_CREATED_FROM;
(function (E_ACCOUNT_CREATED_FROM) {
    E_ACCOUNT_CREATED_FROM["OFFICIAL_WEBSITE"] = "OFFICIAL_WEBSITE";
    E_ACCOUNT_CREATED_FROM["CONVERTLAB"] = "CONVERTLAB";
    E_ACCOUNT_CREATED_FROM["TRIAL_REQUEST"] = "TRIAL_REQUEST";
    E_ACCOUNT_CREATED_FROM["PURCHAS_SELF_REGISTER"] = "PURCHAS_SELF_REGISTER";
    E_ACCOUNT_CREATED_FROM["PHARMSNAP_FREE_REGISTER"] = "PHARMSNAP_FREE_REGISTER";
    E_ACCOUNT_CREATED_FROM["BO"] = "BO";
    E_ACCOUNT_CREATED_FROM["ADMIN_PORTAL"] = "ADMIN_PORTAL";
    E_ACCOUNT_CREATED_FROM["CRM"] = "CRM";
    E_ACCOUNT_CREATED_FROM["MINI_PROGRAME"] = "MINI_PROGRAME";
    E_ACCOUNT_CREATED_FROM["APP"] = "APP";
})(E_ACCOUNT_CREATED_FROM || (E_ACCOUNT_CREATED_FROM = {}));
export const registerUrlParamKeys = [
    'created_from', // 创建来源
    'search_channel', // 搜索渠道
    'channel_code', // 渠道编码
    'channel_source', // 渠道来源
    'campaign_promotion', // 项目活动
    'lead_source', // 线索来源
    'campaign_id',
    'media_type', // 媒介类型
    'search_keywords', // 搜索关键词
    'promotion_plan', // 推广计划
    'promotion_cell', // 推广单元
    'from_page', // 页面名称
    'from_button', // 按钮名称
    'official_site_link', // 官网域名链接
    'client', // 客户端
    'province', // 省份
    'city' // 城市
];
export var E_SELF_REGISTER_ACTIVITY_STATUS;
(function (E_SELF_REGISTER_ACTIVITY_STATUS) {
    /**
     * $t('backoffice.operationPromotionLink.E_ACTIVITY_STATUS.NOT_EFFECTIVE')
     */
    E_SELF_REGISTER_ACTIVITY_STATUS["NOT_EFFECTIVE"] = "NOT_EFFECTIVE";
    /**
     * $t('backoffice.operationPromotionLink.E_ACTIVITY_STATUS.EFFECTIVE')
     */
    E_SELF_REGISTER_ACTIVITY_STATUS["EFFECTIVE"] = "EFFECTIVE";
    /**
     *  $t('backoffice.operationPromotionLink.E_ACTIVITY_STATUS.EXPIRED')
     */
    E_SELF_REGISTER_ACTIVITY_STATUS["EXPIRED"] = "EXPIRED";
    /**
     * $t('backoffice.operationPromotionLink.E_ACTIVITY_STATUS.SUSPEND')
     */
    E_SELF_REGISTER_ACTIVITY_STATUS["SUSPEND"] = "SUSPEND";
})(E_SELF_REGISTER_ACTIVITY_STATUS || (E_SELF_REGISTER_ACTIVITY_STATUS = {}));
// 活动推广必填选项包含：必填、选填、不显示，
export var E_ACTIVITY_OBJECT;
(function (E_ACTIVITY_OBJECT) {
    /**
     * 必填
     */
    E_ACTIVITY_OBJECT["REQUIRED"] = "REQUIRED";
    /**
     * 选填
     */
    E_ACTIVITY_OBJECT["DISPLAY"] = "DISPLAY";
    /**
     * 不显示
     */
    E_ACTIVITY_OBJECT["HIDDEN"] = "HIDDEN";
})(E_ACTIVITY_OBJECT || (E_ACTIVITY_OBJECT = {}));
