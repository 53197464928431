import _ from 'lodash';
import Vue, { computed, getCurrentInstance } from 'vue';
export function useI18n(options) {
    const instance = getCurrentInstance();
    const i18n = instance?.proxy?.$i18n;
    if (!i18n || !instance) {
        console.warn('[I18n Error Instance]: ', instance);
        throw new Error('当前组件，无法获取i18N');
    }
    // Merge I18n Messages
    if (options?.messages) {
        _.forEach(options.messages, (messages, locale) => {
            i18n.mergeLocaleMessage(locale, messages);
        });
    }
    function mergeLocaleMessage(locale, message) {
        if (Vue.version.startsWith('3')) {
            console.error('Vue3 下可能不支持 mergeLocaleMessage');
        }
        i18n && i18n.mergeLocaleMessage(locale, message);
    }
    function t(key, values) {
        return i18n?.t(key, values);
    }
    function te(key) {
        return i18n?.te(key);
    }
    function tc(key, choice, values) {
        return i18n?.tc(key, choice, values);
    }
    const locale = computed(() => {
        return i18n.locale;
    });
    return {
        t,
        tc,
        te,
        locale,
        mergeLocaleMessage,
    };
}
