import Vue from 'vue';
import 'library-components/assets/styles/reset.scss';
import '@patsnap-biz/design-tokens/scss/_variables-IP.css';
import 'library-components/assets/styles/el-overwrites/index.scss';
import setWebsiteFont from 'library-components/utils/configure-website-font';
import { initTransformCodeInterceptor } from 'library-components/apis/interceptors/transform_code.interceptor';
import { IS_Prod, PASSPORT_CLIENT_ID, PASSPORT_CLIENT_NAME } from 'library-components/config/envConfig';
import { loginInitApi } from 'library-components/apis/login/login';
import { getLangValue } from 'library-components/utils/langUtils';
import _ from 'lodash';
import { removeEnterLoginCookie } from 'library-components/services/cookie';
import { NormalService } from 'library-components/services/publicService';
import { CommonService } from 'library-components/services/commonService';
import { makeLocale, fetchMessages } from './infrastructure/locales';
import RootComponent from './RootComponent.vue';
import '@/presentation/base_components/element';
import '@/presentation/assets/style/index.scss';
import VueLazyLoad from 'vue-lazyload';
import { E_PROJECT_NAME } from 'library-components/config/types';
import { getVar } from 'library-components/utils/environment';
import router from './presentation/router';
import { LoginService } from '@/services/loginService';
import { applyCommonHeaderConfigInterceptor } from 'library-components/config/authInterceptor';
import { provideLoginServices } from './provideServices';
// @ts-nocheck
Vue.use(VueLazyLoad, {
    preLoad: 1.3,
    attempt: 1,
});
const debug = !IS_Prod;
const startup = async () => {
    applyCommonHeaderConfigInterceptor();
    const lang = await getLangValue(false);
    const i18n = makeLocale(lang, lang);
    const locales = await fetchMessages(i18n, lang);
    initTransformCodeInterceptor(locales);
    Vue.config.devtools = debug;
    Vue.config.productionTip = debug;
    const commonService = Vue.observable(new CommonService());
    const service = Vue.observable(new NormalService(false));
    const loginService = Vue.observable(new LoginService({ i18n: i18n }));
    const provideServices = provideLoginServices(i18n);
    // eslint-disable-next-line no-new
    new Vue({
        el: '#login-app',
        // @ts-ignore
        i18n: locales,
        router,
        provide: {
            service,
            commonService,
            loginService,
            ...provideServices,
        },
        render: h => h(RootComponent),
    });
    removeEnterLoginCookie();
    setWebsiteFont(lang);
    loginInitApi().then((res) => {
        const client_id = getVar('client_id') || PASSPORT_CLIENT_ID;
        const from = getVar('from') || PASSPORT_CLIENT_NAME;
        const initData = _.merge({
            client_id,
            from,
            region: res.region,
            projectName: E_PROJECT_NAME.Login,
            lang,
        }, { globalData: res });
        _.set(window, 'zPREFACE', initData);
        commonService.init(initData);
        service.init(initData);
    });
};
startup();
