import { LoginSuccessService, LoginSuccessServiceImpl } from 'library-components/services/login-success-service';
import { RegisterLoginSuccessService, RegisterLoginSuccessServiceImpl } from 'library-components/services/register-login-success-service';
import { WechatBindLoginSuccessService, WechatBindLoginSuccessServiceImpl } from 'library-components/services/wechat-bind-login-service';
export function provideLoginServices(i18n) {
    return {
        [LoginSuccessService]: new LoginSuccessServiceImpl(i18n),
        [RegisterLoginSuccessService]: new RegisterLoginSuccessServiceImpl(i18n),
        [WechatBindLoginSuccessService]: new WechatBindLoginSuccessServiceImpl(i18n),
    };
}
