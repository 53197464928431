import _ from 'lodash';
/**
 * URL 上的from
 */
export var E_PRODUCTS_NAME;
(function (E_PRODUCTS_NAME) {
    E_PRODUCTS_NAME["analytics"] = "analytics";
    E_PRODUCTS_NAME["insights"] = "insights";
    E_PRODUCTS_NAME["chemical"] = "chemical";
    E_PRODUCTS_NAME["bio"] = "lifescience";
    E_PRODUCTS_NAME["discovery"] = "discovery";
    E_PRODUCTS_NAME["course"] = "course";
    E_PRODUCTS_NAME["course2b"] = "course2b";
    E_PRODUCTS_NAME["academy"] = "academy";
    E_PRODUCTS_NAME["inno"] = "inno";
    E_PRODUCTS_NAME["synapse"] = "synapse";
    E_PRODUCTS_NAME["synapse_cn"] = "synapse_cn";
    E_PRODUCTS_NAME["tffi"] = "tffi";
    E_PRODUCTS_NAME["openapi"] = "openapi";
    E_PRODUCTS_NAME["eureka"] = "eureka";
    E_PRODUCTS_NAME["yunchuang"] = "yunchuang";
    E_PRODUCTS_NAME["innocloud"] = "innocloud";
    E_PRODUCTS_NAME["ls360"] = "ls360";
})(E_PRODUCTS_NAME || (E_PRODUCTS_NAME = {}));
(function (E_PRODUCTS_NAME) {
    function isFreeProduct(val) {
        const productName = _.toLower(val);
        return _.includes([
            E_PRODUCTS_NAME.synapse,
            E_PRODUCTS_NAME.openapi,
            E_PRODUCTS_NAME.ls360,
            E_PRODUCTS_NAME.eureka
        ], productName);
    }
    E_PRODUCTS_NAME.isFreeProduct = isFreeProduct;
    /**
     * 付费产品
     */
    function isPaidProduct(val) {
        const productName = _.toLower(val);
        return _.includes([
            E_PRODUCTS_NAME.analytics,
            E_PRODUCTS_NAME.insights,
            E_PRODUCTS_NAME.bio,
            E_PRODUCTS_NAME.chemical,
            E_PRODUCTS_NAME.discovery,
            E_PRODUCTS_NAME.tffi
        ], productName);
    }
    E_PRODUCTS_NAME.isPaidProduct = isPaidProduct;
    function notFreeApplyProducts(val) {
        const productName = _.toLower(val);
        return _.includes([
            E_PRODUCTS_NAME.bio,
            E_PRODUCTS_NAME.chemical,
            E_PRODUCTS_NAME.discovery,
            E_PRODUCTS_NAME.yunchuang
        ], productName);
    }
    E_PRODUCTS_NAME.notFreeApplyProducts = notFreeApplyProducts;
})(E_PRODUCTS_NAME || (E_PRODUCTS_NAME = {}));
/**
 * 公众号appname
 */
export var E_WECHAT_APP_NAME;
(function (E_WECHAT_APP_NAME) {
    E_WECHAT_APP_NAME["EUREKA"] = "EUREKA";
    E_WECHAT_APP_NAME["ZHIHUIYA"] = "ZHIHUIYA";
    E_WECHAT_APP_NAME["TFFI"] = "TFFI";
    E_WECHAT_APP_NAME["SYNAPSE"] = "SYNAPSE";
    E_WECHAT_APP_NAME["YUNCHUANG"] = "YUNCHUANG";
})(E_WECHAT_APP_NAME || (E_WECHAT_APP_NAME = {}));
/**
 * 第三方
 */
export var E_THRID_PARTS;
(function (E_THRID_PARTS) {
    /**
     * $t('passport.E_THIRD_PARTS.wechat')
     */
    E_THRID_PARTS["WECHAT"] = "wechat";
    /**
     * $t('passport.E_THIRD_PARTS.wechat-enterprise')
     */
    E_THRID_PARTS["WECOM"] = "wechat-enterprise";
    /**
     * $t('passport.E_THIRD_PARTS.dingtalk')
     */
    E_THRID_PARTS["DING_TALK"] = "dingtalk";
    /**
     * $t('passport.E_THIRD_PARTS.feishu')
     */
    E_THRID_PARTS["FEISHU"] = "feishu";
    /**
     * $t('passport.E_THIRD_PARTS.inno_cloud')
     */
    E_THRID_PARTS["INNOCLOUD"] = "inno_cloud";
    /**
     * SAML/CARSI
     */
    E_THRID_PARTS["SAML"] = "saml";
})(E_THRID_PARTS || (E_THRID_PARTS = {}));
export var INCITATION_CODE_TYPE;
(function (INCITATION_CODE_TYPE) {
    INCITATION_CODE_TYPE["SHORT_CODE"] = "short_code";
    INCITATION_CODE_TYPE["COMPANY"] = "company";
    INCITATION_CODE_TYPE["FREE"] = "free"; // 免费产品微信注册页面
})(INCITATION_CODE_TYPE || (INCITATION_CODE_TYPE = {}));
