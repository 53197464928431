import { goToModule } from 'library-components/utils/goToModule';
import { getLoginLocaleStatus, removeLoginLocaleStatus } from './localStorage';
import { setLangValue } from 'library-components/utils/langUtils';
import { applyAuthInterceptor } from 'library-components/config/authInterceptor';
export class AbstractLoginSuccess {
    i18n;
    constructor(i18n) {
        this.i18n = i18n;
    }
    applyAuthInterceptor(token) {
        applyAuthInterceptor(token);
    }
    async handleLocaleChange() {
        const localeChanged = getLoginLocaleStatus();
        if (localeChanged) {
            try {
                await setLangValue(this.i18n.locale, true).then(() => {
                    removeLoginLocaleStatus();
                });
            }
            catch (error) {
                console.log(error);
            }
        }
    }
    goToModule(opts) {
        return goToModule(opts);
    }
    t(key, ...args) {
        return this.i18n.t(key, ...args);
    }
    async handleLoginSuccess(opts) {
        const { token, ...goToModuleOpts } = opts;
        this.applyAuthInterceptor(token);
        await this.handleLocaleChange();
        await this.goToModule(goToModuleOpts);
    }
}
export const LoginSuccessService = Symbol('LoginSuccessService');
export class LoginSuccessServiceImpl extends AbstractLoginSuccess {
}
